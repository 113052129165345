import {QRCode, Input, message, Divider} from "antd";
import {useRecoilValue} from "recoil";
import {
    globalReferralSettingState,
    globalUserDetailState,
} from "../../../../_common/global_state";
import {useTranslation} from "react-i18next";
import useShared from "../../../../_common/_shared";
import copy from "copy-to-clipboard";
import React, {useState} from "react";

const {TextArea} = Input;

const QrModal = () => {
    const {t} = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const userDetails = useRecoilValue(globalUserDetailState);
    const qrReferral = useRecoilValue(globalReferralSettingState);
    const mainDomain = window.location.hostname.split(".").slice(-2).join(".");
    const {IconCommon} = useShared()

    const copyLink = (link) => {
        copy(link);
        message.success(t("user.successMsg.affiliateLinkCopiedToClipboard"));
    };

    const items = [
        qrReferral?.referralLink && {
            key: 1,
            title: t("referral.homepage"),
            value: "https://" + mainDomain + "/?affiliate=" + userDetails?.affiliateCode,
        },
        qrReferral?.liveReferralLink && {
            key: 2,
            title: t("referral.live"),
            value: "https://member." + mainDomain + "/#/register?type=individual&affiliate=" + userDetails?.affiliateCode,
        },
        qrReferral?.demoReferralLink && {
            key: 3,
            title: t("referral.demo"),
            value: "https://member." + mainDomain + "/#/register?type=demo&affiliate=" + userDetails?.affiliateCode,
        },
    ]

    return (
        <div className={"modalQr"}>
            {items.map((item) => {
                return (
                    <div className={"box-border"}>
                        <div
                            className={"qr-title"}
                            onClick={() =>
                                setExpanded((prev) => {
                                    if (prev === item?.key) {
                                        return null;
                                    } else {
                                        return item?.key;
                                    }
                                })
                            }
                        >
                            {item?.title}
                            <IconCommon
                                iconName={"cm-arrowrightshort-regular"}
                            />
                        </div>
                        <div className={`expanded-qr${expanded === item?.key ? "-active" : ""}`}>
                            <Divider/>
                            <div
                                className="header-qr-container"
                                onClick={() => copyLink(item?.value)}
                            >
                                <QRCode value={item?.value}/>
                            </div>
                            <TextArea
                                defaultValue={item?.value}
                                onClick={() => copyLink(item?.value)}
                                readOnly
                                autoSize
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default QrModal;
