import React from "react";
import {BRAND_NAME} from "../../../../config/_variable";
import ExcotradesEmptyData from "./excotrades";
import RidgewaymarketsEmptyData from "./ridgewaymarkets";
import AlliumtradesEmptyData from "./alliumtrades";
import MinosEmptyData from "./minosmarkets";
import CronoscapEmptyData from "./cronoscap";
import FundaEmptyData from "./fundamarkets";
import MetaproEmptyData from "./metapromarkets";
import QuorvexEmptyData from "./quorvex";
import BlueOceanEmptyData from "./blueocean";
import AntosEmptyData from "./antos";

export default function EmptyData(props) {
    const {title} = props;
    let component;
    switch (BRAND_NAME) {
        case "fundamarkets":
            component = <FundaEmptyData title={title}/>;
            break;
        case "excotrades":
            component = <ExcotradesEmptyData title={title}/>;
            break;
        case "ridgewaymarkets":
            component = <RidgewaymarketsEmptyData title={title}/>;
            break;
        case "minosmarkets":
            component = <MinosEmptyData title={title}/>;
            break;
        case "cronoscap":
            component = <CronoscapEmptyData title={title}/>;
            break;
        case "alliumtrades":
            component = <AlliumtradesEmptyData title={title}/>;
            break;
        case "metapromarkets":
            component = <MetaproEmptyData title={title}/>
            break
        case "quorvex":
            component = <QuorvexEmptyData title={title}/>
            break
        case "blueocean":
            component = <BlueOceanEmptyData title={title}/>
            break
        case "antos":
        case "antospnc":
            component = <AntosEmptyData title={title}/>
            break
        default:
            component = <ExcotradesEmptyData/>;
            break;
    }

    return component;
}
