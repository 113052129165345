import React, {useEffect, useState} from "react";
import {Avatar, Button, Col, Drawer, Layout, Row, Tag,} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    globalBrandSettingState, globalBreadCrumbState,
    globalColorThemeState,
    globalLanguageListState,
    globalOpenGoLiveModalState,
    globalUserDetailState,
    globalUserWalletState,
} from "../../../_common/global_state";
import {ArrowRightOutlined, CheckOutlined, LeftOutlined} from "@ant-design/icons";
import QrModal from "./qr/qrModal";
import {formatMoney} from "../../../_common/function";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import dummyImg from "../../../assets/image/dummy.png";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import GoLiveModal from "./goLive";
import {useVerification} from "../../../_common/_authorize";
import useLayoutFunction from "../../function";

export const Header_2 = (props) => {
    const {registerField} = props;
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const {handleTokenExpiration} = useVerification();
    const {changeLanguageHandler} = useLayoutFunction()
    const {Header} = Layout;

    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const walletState = useRecoilValue(globalUserWalletState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);

    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const [innerDrawerIsOpen, setInnerDrawerIsOpen] = useState(false);
    const [innerDrawerType, setInnerDrawerType] = useState("");
    const [open, setOpen] = useState(false);
    const [openQrModal, setOpenQrModal] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    const VerifyStatusTag = () => {
        const statusMap = {
            "draft verification": {color: "volcano", label: "user.statusTag.draftVerification"},
            "verification fail": {color: "orange", label: "user.statusTag.verificationFail"},
            "not verified": {color: "default", label: "user.statusTag.not_verified"},
            "pending verification": {color: "yellow", label: "user.statusTag.pending_verification"},
            "verified": {color: "green", label: "user.statusTag.verified"},
            "verification expired": {color: "yellow", label: "user.statusTag.expired"}
        };

        const {color, label} = statusMap[userDetails?.status] || {};

        return <Tag className={"me-0"} bordered={false} color={color}>{t(label)}</Tag>
    }

    useEffect(() => {
        preload();
    }, []);

    const openInnerDrawer = (type) => {
        setInnerDrawerIsOpen(true)
        setInnerDrawerType(type)
    }

    const switchColorTheme = (theme) => {
        setColorState(theme);
        localStorage.setItem("color", theme);
    }

    const navigateTo = (link) => {
        navigate(link)
        onClose()
    }

    return (
        <Header id={"layout-header"}>
            <Row align={"middle"}>
                <Col flex={"24px"}>
                    <Button type={"link"} onClick={showDrawer} className={"p-0"}>
                        <img
                            className={"clickable"}
                            width={24}
                            src={
                                colorState === "dark"
                                    ? brandSettingState?.siteFaviconInverse
                                    : brandSettingState?.siteFavicon
                            }
                        />
                    </Button>
                </Col>

                <Col flex={1}>
                    {
                        globalBreadCrumb.slice(-1)?.[0]?.name &&
                        <div className={"breadcrumb-text"}>{t(`module.${globalBreadCrumb.slice(-1)?.[0]?.name?.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase()}`)}</div>
                    }
                </Col>
            </Row>


            {/* DRAWER */}
            <Drawer
                placement={"left"}
                getContainer={() => document.getElementById("layout-2")}
                className={"header-drawer"}
                closeIcon={<Button icon={<LeftOutlined/>} shape={"circle"}/>}
                onClose={onClose}
                open={open}
            >
                <Row gutter={[8, 8]} align={"middle"} className={"mb-3"}>
                    <Col flex={"56px"} className={"text-center"}>
                        <Avatar
                            shape={"square"}
                            size={56}
                            src={userDetails?.profileImage || dummyImg}
                        />
                    </Col>
                    <Col flex={1}>
                        <Row justify={"space-between"} align={"middle"}>
                            <div>
                                <h3 className={"mt-1"}>{t("common.hello")}, <strong>{userDetails?.username}</strong></h3>
                                <p className={"secondary-text"}>{userDetails?.email}</p>
                            </div>

                            <Button onClick={() => navigateTo("/profile")} className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("user.rank")}</p>
                            <p>{userDetails?.rank?.name}</p>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("user.mobileNo")}</p>
                            <p>{userDetails?.completedMobileNo}</p>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("ewallet.balance")}</p>
                            <p>$ {formatMoney(walletState?.balance)}</p>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            {/*todo - translation*/}
                            <p>{t("module.accountVerification")}</p>
                            <div>
                                {VerifyStatusTag()}
                                {
                                    !["pending verification", "verified"].includes(userDetails?.status) &&
                                    <Button onClick={() => navigateTo("/account-verification")}
                                            className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                                }
                            </div>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            {/*todo - translation*/}
                            <p>{t("Account Type")}</p>
                            <div>
                                <Tag className={"me-0"} bordered={false}>{t(`user.statusTag.${userDetails?.clientAccountType}`)}</Tag>
                                {
                                    userDetails?.clientAccountType === "demo" &&
                                    <Button onClick={() => setOpenGoLiveModal(true)} className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                                }
                            </div>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("common.language")}</p>
                            <div className={"d-inline-flex"}>
                                <p>{languageListState?.find(d => d?.code === localStorage.getItem("language"))?.nativeName}</p>
                                <Button onClick={() => openInnerDrawer("language")} className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                            </div>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("common.theme")}</p>
                            <div className={"d-inline-flex"}>
                                <p>{t(`common.${localStorage.getItem("color")}`)}</p>
                                <Button onClick={() => openInnerDrawer("theme")} className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                            </div>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("module.referral")}</p>
                            <Button onClick={() => setOpenQrModal(true)} className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                        </Row>
                        <Row className={"info-sections"} justify={"space-between"}>
                            <p>{t("module.platformDownload")}</p>
                            <Button onClick={() => setOpenPlatformModal(true)} className={"go-btn"} icon={<ArrowRightOutlined/>} type={"link"}/>
                        </Row>
                    </Col>
                </Row>
                <Row className={"mt-auto mb-3"} gutter={[8, 8]}>
                    <Col span={12}>
                        <Button
                            type={"primary"} block
                            onClick={() => navigateTo("/wallet-deposit")}
                        >
                            {t("module.deposit")}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            block
                            onClick={() => {
                                onClose();
                                handleTokenExpiration();
                            }}
                        >
                            {t("common.logOut")}
                        </Button>
                    </Col>
                </Row>
            </Drawer>
            <Drawer
                id={"inner-drawer"}
                className={"header-drawer"}
                open={innerDrawerIsOpen}
                closeIcon={<Button icon={<LeftOutlined/>} shape={"circle"}/>}
                onClose={() => setInnerDrawerIsOpen(false)}
                getContainer={() => document.getElementById("layout-2")}
            >
                <h2>{t(`common.${innerDrawerType}`)}</h2>
                {
                    innerDrawerType === "language" ?
                        <div>
                            {
                                languageListState?.map(d => (
                                    <Row onClick={() => changeLanguageHandler(d?.code)} className={"info-sections"} justify={"space-between"}>
                                        <p>{d?.nativeName}</p>
                                        {
                                            localStorage.getItem("language") === d?.code &&
                                            <p><CheckOutlined/></p>
                                        }
                                    </Row>
                                ))
                            }
                        </div>
                        : innerDrawerType === "theme" ?
                            <div>
                                {
                                    ["light", "dark"].map(theme => (
                                        <Row onClick={() => switchColorTheme(theme)} className={"info-sections"} justify={"space-between"}>
                                            <p>{t(`common.${theme}`)}</p>
                                            {
                                                localStorage.getItem("color") === theme &&
                                                <p><CheckOutlined/></p>
                                            }
                                        </Row>
                                    ))
                                }
                            </div>
                            : <></>
                }
            </Drawer>
            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
            />
            <GoLiveModal
                isModalOpen={openGoLiveModal}
                handleCancel={() => setOpenGoLiveModal(false)}
                registerField={registerField}
            />
        </Header>
    );
};
