import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const VERIFY_ACCESS_TOKEN = gql`
    mutation verifyAccessToken($accessToken: String) {
        verifyAccessToken(accessToken: $accessToken)
        {
            ...on AuthPayload{
                login
                accessToken
                userid
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`

export async function verifyAccessTokenMutation(token) {

    const variables = {
        accessToken: token
    };
    const execute = await ApolloClientQuery(VERIFY_ACCESS_TOKEN, variables, true);
    return execute;
}

const LOGIN = gql`
    mutation login($input: AuthInput) {
        login(input: $input)
        {
            ...on AuthPayload{
                login
                accessToken
                userid
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`

export async function loginMutation(input) {

    const variables = {
        input: {
            login: input.login || null,
            password: input.password || null,
            type: input.type || null,
            deviceId: input?.deviceId || null,
            ip: input?.ip || null,
            // platform: "web",
        }
    };
    const execute = await ApolloClientQuery(LOGIN, variables, true);
    return execute;
}

const GET_REGISTRATION_SETTING = gql`
    query getRegistrationSetting($filter: RegistrationSettingSearchInput) {
        getRegistrationSetting(filter: $filter) {
            ... on RegistrationSettingArray {
                data {
                    id
                    position
                    fieldList{
                        isRequired
                        isConfirmRequired
                        label
                        fieldName
                        fieldType
                        category
                        optionValue{
                            label
                            value
                        }
                        isUsedForLogin
                        minCharacterLimit
                        maxCharacterLimit
                    }
                }
            }
        }
    }
`;

export async function getRegistrationSettingQuery(mode) {

    const variables = {
        filter: {
            platform: "mobile",
            mode: mode === "demo" ? mode : 'live',
            hasPosition: true
        },
    };
    const execute = await ApolloClientQuery(GET_REGISTRATION_SETTING, variables, false);

    return execute;
}

const USER_REGISTER = gql`
    mutation register($input: RegistrationInput) {
        register(input: $input) {
            ... on User {
                id
                _id
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                desk {name}
                role {name}
                referrer {
                    _id
                    fullName
                }
                salesRep {
                    _id
                    fullName
                }
                eWallet{_id}
                clientAccountType
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

export async function registerMutation(input) {

    const variables = {
        input: {
            ...input,
        }
    };
    const execute = await ApolloClientQuery(USER_REGISTER, variables, true);
    return execute;
}

const FORGET_PASSWORD = gql`
    mutation forgetPassword($input: ForgetPasswordInput) {
        forgetPassword(input: $input) {
            ... on UserMessage {
                msg
                msgKey
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function forgetPassword(input) {

    const variables = {
        input: {
            input: input
        }
    };
    const execute = await ApolloClientQuery(FORGET_PASSWORD, variables, true);
    return execute;
}

const RESET_PASSWORD = gql`
    mutation resetPassword($input: ResetPassswordInput) {
        resetPassword(input: $input) {
            ... on UserMessage {
                msg
                msgKey
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function resetPassword(key, newPassword) {

    const variables = {
        input: {
            resetLink: key,
            newPassword: newPassword
        }
    };
    const execute = await ApolloClientQuery(RESET_PASSWORD, variables, true);
    return execute;
}

const GET_LOGIN_HISTORY = gql`
    query getLoginHistory($filter: LoginHistorySearchInput,$limit:Int,$offset:Int,$orderBy:String) {
        getLoginHistory(filter: $filter,limit:$limit,offset:$offset,orderBy:$orderBy)
        {
            ...on LoginHistory{
                total
                record{
                   loginHistory {
                       loginTime
                       deviceId
                       ipAddress
                       browser
                       status
                   }
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`

export async function getLoginHistory(userid, limit, offset, orderBy) {

    const variables = {
        filter: {
            userid: userid
        },
        limit: limit,
        offset: offset || 0,
        orderBy: orderBy,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_LOGIN_HISTORY, variables, false);
    return execute;
}

const VERIFY_EMAIL = gql`
    mutation verifyEmail($input: VerifyEmailInput) {
        verifyEmail(input: $input){
            ... on User {
                id
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

export async function verifyEmail(input) {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(VERIFY_EMAIL, variables, true);
    return execute;
}

const VERIFY_CRYPTO_ADDRESS = gql`
    mutation verifyCryptoAddress($email: String, $transactionNo: String) {
        verifyCryptoAddress(email: $email, transactionNo: $transactionNo){
            ... on EWalletWithdrawal {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

export async function verifyCryptoAddress(email, transactionNo) {
    const variables = {
        email: email,
        transactionNo: transactionNo,
    };
    const execute = await ApolloClientQuery(VERIFY_CRYPTO_ADDRESS, variables, true);
    return execute;
}

const GO_LIVE = gql`
    mutation goLive($id: String!, $input: UserInput) {
        goLive(id: $id, input: $input){
            ... on User {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

export async function goLive(id, input) {
    const variables = {
        id: id,
        input: input,
    };
    const execute = await ApolloClientQuery(GO_LIVE, variables, true);
    return execute;
}

const RESEND_VERIFICATION_EMAIL = gql`
    mutation resendVerificationEmail($input: ResendVerificationEmailInput) {
        resendVerificationEmail(input: $input){
             ... on ResendVerificationResponse {
                        status
                    }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export async function resendVerificationEmail(input) {
    const variables = {
        input: input,
    };
    const execute = await ApolloClientQuery(RESEND_VERIFICATION_EMAIL, variables, true);
    return execute;
}

