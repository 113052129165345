import { gql } from "@apollo/client";
import { ApolloClientQuery } from "./_setup";

const GET_EWALLET = gql`
  query getEWallet(
    $filter: EWalletSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getEWallet(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on EWalletArray {
        data {
          _id
          id
          user {
            fullName
          }
          balance
          description
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function getEwallet(userid) {
  const variables = {
    filter: {
      userIdList: [userid],
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(GET_EWALLET, variables, false);

  return execute;
}

const GET_EWALLET_BY_ID = gql`
  query getEWalletByID($id: String!) {
    getEWalletByID(id: $id) {
      ... on EWallet {
        _id
        id
        user {
          fullName
        }
        balance
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function getEwalletById(id) {
  const variables = {
    id: id,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(GET_EWALLET_BY_ID, variables, false);

  return execute;
}

const GET_EWALLET_STATEMENT = gql`
  query getEWalletStatement(
    $filter: EWalletStatementSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getEWalletStatement(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on EWalletStatementArray {
        data {
          _id
          statementDate
          type
          description
          user {
            _id
          }
          debit
          credit
          balance
          refNo
          eWalletTransactionId
          transactionType
          importId
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function getEwalletStatement(user, limit, offset) {
  const variables = {
    filter: {
      user: user,
    },
    limit: limit || null,
    offset: offset || null,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_EWALLET_STATEMENT,
    variables,
    false
  );

  return execute;
}

const GET_EWALLET_STATEMENT_COUNT = gql`
  query getEWalletStatementCount($filter: EWalletStatementSearchInput) {
    getEWalletStatementCount(filter: $filter)
  }
`;

export async function getEwalletStatementCount(user) {
  const variables = {
    filter: {
      user: user,
      isDeleted: false,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_EWALLET_STATEMENT_COUNT,
    variables,
    false
  );

  return execute;
}

const GET_CLIENT_EWALLET_RECORD = gql`
  query getClientEWalletRecord(
    $filter: EWalletClientSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getClientEWalletRecord(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on EWalletRecordArray {
        data {
          transactionDate
          transactionNo
          eWalletTransactionId
          description
          remark
          transactionType
          amount
          transactionStatus
        }
        count
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function getClientEWalletRecord(filter, limit, offset, orderBy) {
  const variables = {
    filter: filter,
    limit: limit,
    offset: offset,
    orderBy: orderBy,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_CLIENT_EWALLET_RECORD,
    variables,
    false
  );

  return execute;
}

const GET_EWALLET_DEPOSIT = gql`
  query getEWalletDeposit(
    $filter: EWalletDepositSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getEWalletDeposit(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on EWalletDepositArray {
        data {
          transactionNo
          transactionDate
          description
          amount
          transactionStatus
          psp
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function getEwalletDeposit(user, limit, offset, orderBy) {
  const variables = {
    filter: {
      user: user,
      transactionStatus: ["rejected", "approved", "pending"],
    },
    limit: limit || null,
    offset: offset || null,
    orderBy: orderBy,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_EWALLET_DEPOSIT,
    variables,
    false
  );

  return execute;
}

const GET_EWALLET_WITHDRAWAL = gql`
  query getEWalletWithdrawal(
    $filter: EWalletWithdrawalSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getEWalletWithdrawal(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on EWalletWithdrawalArray {
        data {
          _id
          transactionNo
          transactionDate
          amount
          transactionStatus
          remark
          bankInformationId {
            formData
          }
          psp
          taskStatus
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function getEwalletWithdrawal(user, limit, offset, orderBy) {
  const variables = {
    filter: {
      user: user,
      transactionStatus: ["rejected", "approved", "pending"],
    },
    limit: limit || null,
    offset: offset || null,
    orderBy: orderBy,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_EWALLET_WITHDRAWAL,
    variables,
    false
  );

  return execute;
}

const CLIENT_DEPOSIT = gql`
  mutation clientDeposit($id: String!, $input: EWalletClientDepositInput) {
    clientDeposit(id: $id, input: $input) {
      ... on EWalletClientDeposit {
        status
        url
      }
      ... on CrmTaskApproval {
        msg
      }
      ... on BaseError {
        errKey
        errMsg
        errObj
      }
    }
  }
`;

export async function clientDeposit(userid, input) {
  const variables = {
    id: userid,
    input: input,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(CLIENT_DEPOSIT, variables, true);

  return execute;
}

const CLIENT_WITHDRAWAL = gql`
  mutation clientWithdrawal(
    $id: String!
    $input: EWalletClientWithdrawalInput
  ) {
    clientWithdrawal(id: $id, input: $input) {
      ... on EWalletClientWithdrawal {
        status
      }
      ... on CrmTaskApproval {
        msg
      }
      ... on BaseError {
        errKey
        errMsg
        errObj
      }
    }
  }
`;

export async function clientWithdrawal(userid, input) {
  const variables = {
    id: userid,
    input: input,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(CLIENT_WITHDRAWAL, variables, true);

  return execute;
}

const CANCEL_WITHDRAWAL = gql`
  mutation cancelEWalletWithdrawal($id: String!) {
    cancelEWalletWithdrawal(id: $id) {
      ... on EWalletWithdrawal {
        _id
        transactionNo
        transactionDate
        amount
        transactionStatus
      }
      ... on CrmTaskApproval {
        msg
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

export async function cancelEWalletWithdrawal(id) {
  const variables = {
    id: id,
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(CANCEL_WITHDRAWAL, variables, true);

  return execute;
}

const GET_EWALLET_DEPOSIT_COUNT = gql`
  query getEWalletDepositCount($filter: EWalletDepositSearchInput) {
    getEWalletDepositCount(filter: $filter)
  }
`;

export async function getEwalletDepositCount(user) {
  const variables = {
    filter: {
      user: user,
      transactionStatus: ["rejected", "approved", "pending"],
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_EWALLET_DEPOSIT_COUNT,
    variables,
    false
  );

  return execute;
}

const GET_EWALLET_WITHDRAWAL_COUNT = gql`
  query getEWalletWithdrawalCount($filter: EWalletWithdrawalSearchInput) {
    getEWalletWithdrawalCount(filter: $filter)
  }
`;

export async function getEwalletWithdrawalCount(user) {
  const variables = {
    filter: {
      user: user,
      transactionStatus: ["rejected", "approved", "pending"],
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(
    GET_EWALLET_WITHDRAWAL_COUNT,
    variables,
    false
  );

  return execute;
}

const GET_BANK_INFO = gql`
  query getBankInformation($filter: BankInformationInput) {
    getBankInformation(filter: $filter) {
      ... on BankInformationArray {
        data {
          user {
            _id
          }
          psp {
            _id
          }
          formData
        }
      }
    }
  }
`;

export async function getBankInformation(user, psp) {
  const variables = {
    filter: {
      user: user,
      psp: psp,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("a_t"),
      },
    },
  };
  const execute = await ApolloClientQuery(GET_BANK_INFO, variables, false);

  return execute;
}
