import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {
    globalBrandConfigState,
    globalBrandModuleSettingState,
    globalCurrentPathState,
    globalIconListState,
    globalMobileModuleSettingState,
    globalUserDetailState,
} from "../_common/global_state";
import {getRegistrationSettingQuery} from "../api/graphql/auth";
import Layout_1 from "./layout_1";
import Layout_2 from "./layout_2";
import Layout_4 from "./layout_4";
import Layout_6 from "./layout_6";
import useLayoutFunction from "./function";
import Layout_5 from "./layout_5";
import Layout_3 from "./layout_3";

export default function Layouts() {
    const [registerField, setRegisterField] = useState([]);
    const mobileModuleSetting = useRecoilValue(globalMobileModuleSettingState);
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
    const iconList = useRecoilValue(globalIconListState);
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const userDetails = useRecoilValue(globalUserDetailState);
    const globalPathState = useRecoilValue(globalCurrentPathState);
    const {preloadRoute} = useLayoutFunction();

    const preloadRegisterLiveField = async () => {
        if (userDetails?.clientAccountType === "demo") {
            const response = await getRegistrationSettingQuery("live");
            setRegisterField(response?.getRegistrationSetting?.data);
        }
    };


    useEffect(() => {
        preloadRegisterLiveField();
    }, [userDetails]);

    useEffect(() => {
        preloadRoute(brandConfigState, brandModuleSetting, mobileModuleSetting)
    }, [
        brandConfigState,
        mobileModuleSetting,
        iconList,
        brandModuleSetting,
        globalPathState,
        userDetails
    ]);

    let component;
    const props = {
        registerField: registerField,
        userDetails: userDetails
    };

    switch (brandConfigState?.defaultLayout) {
        case 1:
            component = <Layout_1 {...props} />;
            break;

        case 2:
            component = <Layout_2 {...props} />;
            break;

        case 3:
            component = <Layout_3 {...props} />;
            break;

        case 4:
            component = <Layout_4 {...props} />;
            break;

        case 5:
            component = <Layout_5 {...props} />;
            break;

        case 6:
            component = <Layout_6 {...props} />;
            break;

        default:
            component = <Layout_2 {...props} />;
            break;
    }

    return component;
}
