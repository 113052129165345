import {useRecoilState, useSetRecoilState} from "recoil";
import {globalNotificationState, globalUserDetailState,} from "../../../_common/global_state";
import {batchAddUserKyc} from "../../../api/graphql/kyc";
import {getUserById} from "../../../api/graphql/user";
import {useVerification} from "../../../_common/_authorize";
import {
    cancelUserVerificationRecord,
    completeUserVerificationRecord,
    createUserVerificationRecord,
    createUserVerificationRecordDocument,
    saveUserVerificationRecord,
} from "../../../api/graphql/accountVerification";
import {useTranslation} from "react-i18next";
import {getFieldList} from "../../../api/graphql/profileInformation";

const useAccountVerificationFunction = () => {
    const {t} = useTranslation()
    const [userDetails, setUserDetails] = useRecoilState(globalUserDetailState);
    // const [verificationRecords, setVerificationRecords] = useRecoilState(
    //   globalUserAccountVerificationRecordsState
    // );
    const setNotificationMessage = useSetRecoilState(globalNotificationState);

    const { baseErrorChecking, getClientDeviceInfo } = useVerification();

    const addKycFunction = async (input, verificationType) => {
        try {
            const response = await batchAddUserKyc(input, verificationType);

            if (response.batchAddUserKyc.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("successMsg.pendingApproval"),
                    key: "accountVerificationNotification",
                });

                const userDetail = await getUserById(userDetails?._id);
                setUserDetails(userDetail?.getUserByID);
                return {
                    status: "success",
                };
            } else if (response.batchAddUserKyc.__typename === "BaseError") {
                return await baseErrorChecking(response.batchAddUserKyc?.errKey);
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Submit Successfully",
                    key: "accountVerificationNotification",
                });
                const userDetail = await getUserById(userDetails?._id);
                setUserDetails(userDetail?.getUserByID);

                return {
                    status: "success",
                };
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const reorderValue = async (
        data,
        value,
        isMember = false,
        index = 0,
        detailMember = {}
    ) => {
        let array = [];
        await Object.keys(value).map((v) => {
            if (!v.includes("_expiredDate")) {
                const detail = data.find((d) => d._id === v);
                let currentValue = null;
                if (detail?.kycField?.fieldType === "text") {
                    currentValue = value[v];
                } else if (detail?.kycField?.fieldType === "file") {
                    currentValue = value[v].file.response[0].link.toString();
                } else if (detail?.kycField?.fieldType === "date") {
                    // currentValue = moment(value[v]).toISOString();
                    currentValue = value[v].toISOString();
                }

                const expiredKey = v + "_expiredDate";
                let expiredDatetime = null;
                if (value[expiredKey] !== undefined) {
                    // expiredDatetime = moment(value[expiredKey]).toISOString();
                    expiredDatetime = value[expiredKey].toISOString();
                }

                const obj = {
                    kycField: detail?.kycField?._id,
                    kycSetting: detail?._id,
                    value: currentValue,
                    user: userDetails?._id,
                    expiredDatetime: expiredDatetime,
                    isMemberField: isMember,
                    memberId: index,
                    memberKycSetting: detailMember?._id || null,
                };

                array.push(obj);
            }
        });

        return array;
    };

    const handleCreateNewDocumentRecord = async ({
                                                     groupSetting,
                                                     document,
                                                     user,
                                                     record,
                                                 }) => {
        let deviceInfo;
        try {
            deviceInfo = await getClientDeviceInfo();

            const createUserVerificationPayload = {
                groupSetting: groupSetting,
                document: document,
                user,
                record,
                browser: deviceInfo?.browser,
                device: deviceInfo?.device,
                deviceToken: deviceInfo?.deviceToken,
                ip: deviceInfo?.ip,
            };

            return createUserVerificationRecordDocument(
                createUserVerificationPayload
            );
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    // const handleCompleteVerification = async () => {
    //   try {
    //     await completeUserVerificationRecordDocument({
    //       user: userDetails._id,
    //     });

    //     await refetchUser();

    //     setNotificationMessage({
    //       status: "success",
    //       title: "",
    //       msg: "Submit Successfully",
    //       key: "accountVerificationNotification",
    //     });
    //     return true;
    //   } catch (e) {
    //     console.log("Error:", e);
    //     setNotificationMessage({
    //       status: "error",
    //       title: "",
    //       msg: "System Error",
    //       key: "systemNotification",
    //     });
    //     return false;
    //   }
    // };

    const handleCompleteVerification = async (record) => {
        try {
            await completeUserVerificationRecord({
                user: userDetails._id,
                record,
            });

            await refetchUser();

            setNotificationMessage({
                status: "success",
                title: "",
                msg: "Submit Successfully",
                key: "accountVerificationNotification",
            });
            return true;
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
    };

    const refetchUser = async () => {
        try {
            const userDetail = await getUserById(userDetails._id);
            setUserDetails(userDetail?.getUserByID);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const handleCreateUserVerificationRecord = async ({
                                                          countryName,
                                                          clientType,
                                                      }) => {
        try {
            let deviceInfo = await getClientDeviceInfo();

            const createUserVerificationRecordPayload = {
                user: userDetails._id,
                countryName,
                clientType,
                browser: deviceInfo?.browser,
                device: deviceInfo?.device,
                deviceToken: deviceInfo?.deviceToken,
                ip: deviceInfo?.ip,
            };
            const res = await createUserVerificationRecord(
                createUserVerificationRecordPayload
            );

            if (res.createUserVerificationRecord?.__typename === "UserVerificationRecord") {
                // setVerificationRecord(res.createUserVerificationRecord);
                return true;
            } else {
                baseErrorChecking(res.createUserVerificationRecord?.errKey)
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
        return false;
    };

    const handleCancelUserVerificationRecord = async ({ record }) => {
        try {
            const createUserVerificationRecordPayload = {
                user: userDetails._id,
                record: record,
            };

            const res = await cancelUserVerificationRecord(
                createUserVerificationRecordPayload
            );
            if (res.cancelUserVerificationRecord) {
                // setVerificationRecord({});
                return true;
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
        return false;
    };

    // const fetchVerificationRecord = async ({ clientType, countryName, user }) => {
    //   try {
    //     const res = await getUserVerificationRecord({
    //       clientType,
    //       countryName,
    //       user,
    //     });
    //     if (res.getUserVerificationRecord?.data) {
    //       return true;
    //     }
    //   } catch (e) {
    //     console.log("Error:", e);
    //     setNotificationMessage({
    //       status: "error",
    //       title: "",
    //       msg: "System Error",
    //       key: "systemNotification",
    //     });
    //     return false;
    //   }
    //   return false;
    // };

    const handleSaveUserVerificationRecord = async ({
                                                        field,
                                                        documentRecord,
                                                        record,
                                                    }) => {
        try {
            const res = await saveUserVerificationRecord({
                user: userDetails?._id,
                field,
                record,
                documentRecord,
            });
            if (res.saveUserVerificationRecord) {
                return true;
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
    };

    const fetchFieldList = async () => {
        try {
            return await getFieldList()
        } catch (e) {

        }
    }

    return {
        refetchUser,
        addKycFunction,
        reorderValue,
        handleCreateNewDocumentRecord,
        handleCompleteVerification,
        handleCreateUserVerificationRecord,
        handleCancelUserVerificationRecord,
        // fetchVerificationRecord,
        handleSaveUserVerificationRecord,
        fetchFieldList
    };
};

export default useAccountVerificationFunction;
