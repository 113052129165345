import React from "react";
import {BRAND_NAME} from "../../../../config/_variable";
import ExcotradesLoadingScreen from "./layout/excotrades";
import RidgewaymarketsLoadingScreen from "./layout/ridgewaymarkets";
import AlliumLoadingScreen from "./layout/alliumtrades";
import MinosLoadingScreen from "./layout/minosmarkets";
import FundamarketsLoadingScreen from "./layout/fundamarkets";
import MetaproLoadingScreen from "./layout/metapromarkets";
import QuorvexLoadingScreen from "./layout/quorvex";
import BlueOceanLoadingScreen from "./layout/blueocean";
import AntosLoadingScreen from "./layout/antos";
import CronosLoadingScreen from "./layout/cronoscap";

export default function LoadingScreen() {
    let component;
    switch (BRAND_NAME) {
        case "fundamarkets":
            component = <FundamarketsLoadingScreen />
            break
        case "excotrades":
            component = <ExcotradesLoadingScreen />;
            break;
        case "ridgewaymarkets":
            component = <RidgewaymarketsLoadingScreen />;
            break;
        case "alliumtrades":
            component = <AlliumLoadingScreen />;
            break;
        case "minosmarkets":
            component = <MinosLoadingScreen />;
            break;
        case "cronoscap":
            component = <CronosLoadingScreen />;
            break;
        case "metapromarkets":
            component = <MetaproLoadingScreen />
            break
        case "quorvex":
            component = <QuorvexLoadingScreen />
            break
        case "blueocean":
            component = <BlueOceanLoadingScreen />
            break
        case "antos":
        case "antospnc":
            component = <AntosLoadingScreen />
            break

        default:
            component = <ExcotradesLoadingScreen />;
            break;
    }

    return component;
}
