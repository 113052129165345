import React, {useEffect, useState} from "react";
import {Avatar, Col, Divider, Drawer, Layout, Row} from "antd";
import {useRecoilValue} from "recoil";
import {
    globalBrandSettingState,
    globalBreadCrumbState,
    globalColorThemeState,
    globalUserDetailState
} from "../../../_common/global_state";
import QrModal from "./qr/qrModal";
import PlatformDownloadModal from "./platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import dummyImg from "../../../assets/image/dummy.png";
import {useLocation, useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import FooterTime from "./latestTime/footerTime";

export const Header_1 = (props) => {
    const {mobileRoutes, bookmarks} = props;
    const {t} = useTranslation();
    const {Header} = Layout;
    const [openQrModal, setOpenQrModal] = useState(false);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const colorState = useRecoilValue(globalColorThemeState);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {IconCommon} = useShared();
    const location = useLocation();
    const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);

    const exclude = ["/market-buzz", "/ai-technical-screener",]

    const onClose = () => {
        setOpen(false);
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    const formatMobileHeaderRoutes = () => {
        const mobileHeaderRoutes = {};

        if (bookmarks && bookmarks.length > 0) {
            mobileHeaderRoutes["bookmarks"] = bookmarks?.filter(item => item.key !== "home");
        }

        mobileRoutes?.forEach((item) => {
            const categoryName = item?.categoryName || "common";
            if (!mobileHeaderRoutes[categoryName]) {
                mobileHeaderRoutes[categoryName] = [];
            }
            if (item?.path) mobileHeaderRoutes[categoryName].push(item);
        });

        return mobileHeaderRoutes;
    };

    const mobileHeaderRoutes = formatMobileHeaderRoutes();

    return (
        <Header id={"layout-header"} className={"user-select-none"}>
            <Row justify={"space-between"} align={"middle"} wrap={false}>
                <Col>
                    {location?.pathname === "/dashboard" ? (
                        <img
                            alt={""}
                            className={"clickable"}
                            onClick={() => {
                                navigate("/dashboard")
                                onClose()
                            }}
                            height={20}
                            src={colorState === "dark" ? brandSettingState?.siteFaviconInverse : brandSettingState?.siteFavicon}
                        />
                    ) : (
                        <IconCommon
                            className={"header-icon"}
                            iconName={"cm-arrowleftlong-regular"}
                            onClick={() => {
                                if (exclude.includes(location.pathname)) {
                                    navigate("/dashboard")
                                } else {
                                    navigate(-1)
                                }
                            }}
                        />
                    )}
                </Col>
                {location?.pathname !== "/dashboard" && (
                    <div className={"text-small text-weight-medium"}>
                        {globalBreadCrumb?.[globalBreadCrumb?.length - 1]?.name}
                    </div>
                )}
                <IconCommon
                    onClick={() => setOpen(true)}
                    iconName={"cm-hamburger-regular"}
                    className={"header-icon-large"}
                />
                <Drawer
                    className={"header-drawer"}
                    getContainer={() => document.querySelector("#layout-1")}
                    title={
                        <Row className={"header-drawer-title"} justify={"space-between"} align={"middle"}
                             wrap={false}>
                            <Col>
                                <img
                                    alt={""}
                                    className={"clickable"}
                                    onClick={() => {
                                        navigate("/dashboard")
                                        onClose()
                                    }}
                                    height={20}
                                    src={colorState === "dark" ? brandSettingState?.siteFaviconInverse : brandSettingState?.siteFavicon}
                                />
                            </Col>
                            <IconCommon
                                onClick={() => setOpen(false)}
                                iconName={"cm-close-regular"}
                                className={"header-icon-large"}
                            />
                        </Row>
                    }
                    placement={"right"}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    width={"100%"}
                >
                    <div
                        className={"profile-section box-border"}
                        onClick={() => {
                            navigate("/profile")
                            onClose()
                        }}
                    >
                        <div className={"avatar-section"}>
                            <Avatar className={"profile-avatar"} size={48}
                                    src={userDetails?.profileImage || dummyImg}/>
                            <div>
                                <div className="username">{userDetails?.username}</div>
                                <div
                                    className="accountTypeBadge">{t(`user.statusTag.${userDetails?.clientAccountType}`)}</div>
                            </div>
                        </div>
                        <IconCommon
                            isAlwaysInActive
                            className={"header-icon"}
                            iconName={"cm-arrowrightshort-regular"}
                        />
                    </div>
                    <div className={"drawer-menu"}>
                        {Object.keys(mobileHeaderRoutes)?.map((item) => {
                            if (mobileHeaderRoutes[item].length > 0) {
                                return (
                                    <div key={item}>
                                        <div
                                            className={"text-small text-note text-weight-medium"}>{t(`common.${item}`)}</div>
                                        <Divider className={"mt-1 mb-3"}/>
                                        <Row gutter={[16, 16]}>
                                            {mobileHeaderRoutes[item]?.map((d) => {
                                                return (
                                                    <Col
                                                        className={"menu-item"}
                                                        span={8}
                                                        key={d?.key}
                                                        onClick={() => {
                                                            d?.type === "_blank" ? window.open(d?.path, "_blank") : navigate(d?.path);
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        <div>{d?.icon}</div>
                                                        <div className={"text-x-small text-center"}>{d?.label}</div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                )
                            } else return null
                        })}
                    </div>
                    <div className={"text-center text-note"}>
                        <FooterTime/>
                    </div>
                </Drawer>
            </Row>
            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
        </Header>
    );
};
