// FONT
import "@fontsource/inter";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import {Layout} from 'antd';
import {Outlet} from 'react-router-dom';
import "./css/style.css"
import {Header_2} from "./component/header";
import BottomNavigationBar from "./component/bottomNavigationBar";
import {useRecoilValue} from "recoil";
import {globalUserVerifyState} from "../../_common/global_state";

const Layout_2 = ({registerField, userDetails}) => {
    const userVerifyState = useRecoilValue(globalUserVerifyState);

    return (
        <Layout id={"layout-2"}>
            {
                userVerifyState ?
                    <>
                        <Header_2 registerField={registerField}/>
                        <Layout>
                            {/*<Sider_2 routes={routes}/>*/}
                            <Layout id={"layout-body-2"} className={"layout_padding container-xxl"}>
                                {/*{!(location.pathname === '/dashboard') && (*/}
                                {/*    <Breadcrumb_2 kybList={kybList} poiList={poiList} porList={porList} />*/}
                                {/*)}*/}
                                <Outlet/>
                            </Layout>
                        </Layout>
                        {/*<Footer_2/>*/}
                        <BottomNavigationBar/>
                    </> :
                    <Outlet/>
            }
        </Layout>
    );
};
export default Layout_2;