import {Button, Col, Drawer, Modal, Row, Tabs} from "antd";
import React, {useMemo} from "react";
import PlatformDownloadCard from "./platformDownloadCard";
import {useTranslation} from "react-i18next";
import {LeftOutlined} from "@ant-design/icons";

const PlatformDownloadModal = (props) => {
    const {isModalOpen, handleCancel, platformData} = props;

    const {t} = useTranslation();

    const items = useMemo(() => {
        return Object.keys(platformData)?.map((key, index) => {
            return {
                key: index + 1,
                label: t(key),
                children: (
                    <Row key={key} gutter={[8,8]}>
                        {Object.keys(platformData[key])?.map((item, index) => {
                            if (item !== "server" && item !== "__typename") {
                                if (
                                    key &&
                                    platformData[key] &&
                                    item &&
                                    platformData[key].hasOwnProperty(item) &&
                                    platformData[key][item]?.isEnabled === true
                                ) {
                                    return (
                                        <Col key={platformData[key][item]?.displayName + index} xs={24}>
                                            <PlatformDownloadCard data={platformData[key][item]} />
                                        </Col>
                                    );
                                }
                            }
                        })}
                    </Row>
                ),
            };
        });
    }, [platformData]);

    return (
        <Drawer
            className={"platformDownloadModal"}
            open={isModalOpen}
            footer={null}
            onClose={handleCancel}
            closeIcon={<Button icon={<LeftOutlined/>} shape={"circle"}/>}
            getContainer={() => document.getElementById("layout-2")}
        >
            <h2>{t("module.platformDownload")}</h2>
            <Tabs defaultActiveKey="1" items={items} />
        </Drawer>
    );
};

export default PlatformDownloadModal;
