import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_MEMBER_INITIAL_DATA_WITHOUT_TOKEN = gql`
    query getMemberInitialDataWithoutToken($languageCode: String) {
        getMemberInitialDataWithoutToken(languageCode: $languageCode){
            getLanguage {
                ... on LanguageArray {
                    data{
                        code
                        name
                        nativeName
                        _id
                        id
                        countryCode
                        translationFile
                        isActive
                        createdBy {username}
                        updatedBy {username}
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                }
            }
            getLanguageDropdown {
                ... on LanguageDropdownArray {
                    data{
                        _id
                        id
                        name
                        code
                        countryCode
                        nativeName
                        isActive
                        isDeleted
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                }
            }
            getUserBrandSetting {
                ... on UserBrandSetting {
                    companyLogo
                    companyLogoInverse
                    companyLogoSmall
                    companyLogoInverseSmall
                    siteFavicon
                    siteFaviconInverse
                    companyName
                    companyNameShort
                    supportEmail
                    companyAddress
                    companySlogan
                    copyRight
                    timezone
                    pushNotification
                    facebookLink
                    instaLink
                    youtubeLink
                    twitterLink
                    linkedInLink
                    domain
                    defaultDomain
                    aboutPage
                    supportPage
                    companyTermOfUseLink
                    companyPrivacyPolicyLink
                    companyReturnRefundPolicyLink
                    companyRiskDisclosureLink
                    companyTermOfBusinessLink
                    legalDocument {
                        fullLink
                        isUsedForRegistration
                        isShowInLoginPage
                        registerType
                        loginType
                        name
                        displayName
                        tinyMce
                        linkWithDomain
                        pdf {
                            link
                            fileList
                        }
                    }
                    theme
                    layout
                    usernameCharacterLimit
                    website
                }
                ... on BaseError {
                    errKey
                    errMsg
                }
            }
            getIconList {
                ... on IconListArray {
                    data{
                        name
                        type
                        label
                        countryCode
                        iconLightActive{
                            path
                        }
                        iconLightInactive{
                            path
                        }
                        iconDarkActive{
                            path
                        }
                        iconDarkInactive{
                            path
                        }
                        defaultIconLightActive{
                            path
                        }
                        defaultIconLightInactive{
                            path
                        }
                        defaultIconDarkActive{
                            path
                        }
                        defaultIconDarkInactive{
                            path
                        }
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                }
            }
            getReferralSetting {
                ... on ReferralSetting {
                    referralLink
                    liveReferralLink
                    demoReferralLink
                }
                ...on BaseError {
                    errKey
                    errMsg
                }
            }
        }
    }
`;

export async function getMemberInitialDataWithoutToken() {
    const variables = {
        languageCode: localStorage?.getItem("language") || "en"
    };
    const execute = await ApolloClientQuery(GET_MEMBER_INITIAL_DATA_WITHOUT_TOKEN, variables, false);

    return execute?.getMemberInitialDataWithoutToken;
}

const GET_MEMBER_INITIAL_DATA = gql`
    query getMemberInitialData {
        getMemberInitialData {
            getAllAvailableProductByUserId {
            ... on ProductArray {
                data {
                    _id
                    name
                    leverage
                    }
                }
            ... on BaseError {
                errMsg
                errKey
                }
            }
            # getUserSettingByUserID {
            # ... on UserSetting {
                # _id
                # id
                # user {
                    # username
                    # }
                # timezone
                # theme
                # language
                # newsletter {
                    # _id
                    # id
                    # name
                    # label
                    # }
                # }
            # ... on BaseError {
                # errKey
                # errMsg
                # }
            # }
            getProfileInformationSetting {
            ... on ProfileInformationSettingArray {
                data {
                    _id
                    fieldList {
                        _id
                        label
                        fieldName
                        fieldType
                        isSensitive
                        isRequired
                        isUnique
                        isUsedForLogin
                        optionValue {
                            label
                            value
                            key
                            }
                        category
                        isDisplayToClient
                        minCharacterLimit
                        maxCharacterLimit
                        }
                    type
                    position
                    }
                }

            ...on BaseError {
                errKey
                errMsg
                }
            }
            getEWalletSetting {
                ... on EWalletSetting {
                    _id
                    currency
                    depositWithKyc
                    withdrawalWithKyc
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getModuleSetting {
            ... on ModuleSettingArray {
                data{
                    id
                    _id
                   name
                   categoryName
                    parent{
                        _id
                        id
                        name
                        isEnabled
                        }
                    categoryName
                    isEnabled
                    iconLightActive {
                        path
                        }
                    defaultIconDarkActive {
                        path
                        }
                    defaultIconDarkInactive {
                        path
                        }
                    defaultIconLightActive {
                        path
                        }
                    defaultIconLightInactive {
                        path
                        }
                    description
                    iconDarkActive {
                        path
                        }
                    iconDarkInactive {
                        path
                        }
                    iconLightActive {
                        path
                        }
                    iconLightInactive {
                        path
                        }
                    }
                }
            ... on BaseError {
                errKey
                errMsg
                }
            }
        }
    }
`;

export async function getMemberInitialData() {
    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };

    const execute = await ApolloClientQuery(GET_MEMBER_INITIAL_DATA, variables, false);

    return execute?.getMemberInitialData;
}
