import {Drawer, Image} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getPlatform} from "../../../api/graphql/platformDownload";
import useShared from "../../../_common/_shared";
import dummy from "../../../assets/image/dummy_obj.png";

const PlatformDownloadModal = () => {
    const {t} = useTranslation()
    const [platformData, setPlatformData] = useState([]);
    const [activeDrawer, setActiveDrawer] = useState(null);
    const {IconCommon} = useShared();

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    const items = Object.keys(platformData)?.map((key, index) => {
        return {
            key: index + 1,
            label: t(key),
            children:
                <div className={"download-grid"}>
                    {Object.keys(platformData[key])?.map((item) => {
                        if (item !== "server" && item !== "__typename") {
                            if (key && platformData[key] && item && platformData[key].hasOwnProperty(item) && platformData[key][item]?.isEnabled) {
                                const language = localStorage.getItem("language");
                                const platformDisplayName =
                                    platformData[key][item]?.displayNameLanguage?.find((v) => v?.language === language)?.label ??
                                    platformData[key][item]?.displayNameLanguage?.find((v) => v?.language === "en")?.label ??
                                    platformData[key][item]?.displayName;

                                return (
                                    <div
                                        onClick={() => window.open(platformData[key][item]?.link, "_blank")}
                                        className={"box-border"}
                                    >
                                        <Image
                                            src={platformData[key][item]?.icon ? platformData[key][item]?.icon : platformData[key][item]?.defaultIcon ? platformData[key][item]?.defaultIcon : dummy}
                                            style={{objectFit: "cover"}}
                                            preview={false}
                                        />
                                        <div className={"download-name"}>{platformDisplayName || t(key)}</div>
                                    </div>
                                )
                            }
                        }
                    })}
                </div>,
        }
    })

    return (
        <div id={"download"}>
            {items?.map((item) => {
                return (
                    <>
                        <div
                            className={"box-border"}
                            onClick={() => {
                                setActiveDrawer(item?.key)
                            }}
                        >
                            {item?.label}
                            <IconCommon iconName={"cm-arrowrightshort-regular"}/>
                        </div>
                        {activeDrawer === item?.key && (
                            <Drawer
                                width={"100%"}
                                title={
                                    <div className={"text-small text-weight-medium text-center"}>
                                        {item?.label}
                                    </div>
                                }
                                open={activeDrawer === item?.key}
                                getContainer={() => document.getElementById("layout-1")}
                                onClose={() => setActiveDrawer(null)}
                                closeIcon={
                                    <IconCommon
                                        className={"header-icon"}
                                        iconName={"cm-arrowleftlong-regular"}
                                    />
                                }
                            >
                                {item?.children}
                            </Drawer>
                        )}
                    </>
                )
            })}
        </div>
    )
}


export default PlatformDownloadModal