export const getMobileRoute = async (brandConfigState, brandModuleSetting, mobileModuleSetting, hideChildren = false) => {
    let bottomNavigation = []
    let mainNavigation = []
    let moduleName = []
    let moreModule = []

    const {bottomNav, sideNav} = mobileModuleSetting.reduce((acc, nav) => {
        if (nav.type === "bottom") {
            acc.bottomNav.push(nav);
        } else if (nav.type === "side") {
            acc.sideNav.push(nav);
        }
        return acc;
    }, {bottomNav: [], sideNav: []});

    const processNavigation = async (navArray, navigationList) => {
        if (!navArray) return;

        const sortedNavArray = [...navArray].sort((a, b) => a.position - b.position);

        await Promise.all(
            sortedNavArray.map(async (item) => {
                const {
                    path,
                    sub,
                    type,
                    icon,
                    categoryName
                } = await getSettings(item?.moduleId?.name, brandConfigState, brandModuleSetting);

                if (item?.moduleId?.isEnabled) {
                    moduleName.push(item?.moduleId?.name);
                    navigationList.push({
                        key: item?.moduleId?.name,
                        label: item?.moduleId?.name,
                        path: path,
                        sub: sub,
                        type: type,
                        icon: icon,
                        categoryName: item?.moduleId?.categoryName,
                    });
                }
            })
        );
    };

    await processNavigation(bottomNav, bottomNavigation);
    await processNavigation(sideNav, mainNavigation);

    const parents = [...new Set(brandModuleSetting?.filter((item) => item?.parent)?.map(d => d?.parent?.name))]

    let moduleEnabledSetting = brandModuleSetting?.filter((item) => !moduleName?.includes(item?.name) && item?.isEnabled === true && !parents.includes(item?.name))

    moduleEnabledSetting &&
    (await Promise.all(
        moduleEnabledSetting?.map(async (item) => {
            const {
                path,
                sub,
                type,
                icon,
                categoryName
            } = await getSettings(item?.name, brandConfigState, brandModuleSetting)
            moduleName.push(item?.moduleId?.name)

            const enabledSub = sub
                ?.map((d) => {
                    const isEnabled = brandModuleSetting?.find((setting) => setting?.name === d?.key)?.isEnabled
                    if (isEnabled) return d
                    else return null
                })
                ?.filter((d) => !moduleName?.includes(d?.key) && d)

            moreModule.push({
                key: item?.name,
                label: item?.name,
                path: path,
                sub: enabledSub,
                type: type,
                icon: icon,
                categoryName: item?.categoryName,
            })
        })
    ))

    bottomNavigation = [
        {
            key: "home",
            label: "Home",
            path: "/dashboard",
        },
        ...bottomNavigation
    ]

    mainNavigation = [
        ...mainNavigation,
        {
            key: "more",
            label: "More",
            sub: moreModule,
        },
    ]

    return {bottomNavigation, mainNavigation}
}

function getSettings(name, brandConfigState, brandModuleSetting) {
    let path
    let type
    let sub
    let categoryName
    const cmsUrl = localStorage?.getItem("cmsUrl")

    switch (name) {
        case "LIVE Broadcast":
            path = "/live-broadcast"
            break
        case "Newsletter":
            path = "https://www." + cmsUrl + "/#/newsletters"
            type = "_blank"
            break
        case "Education":
            path = "https://www." + cmsUrl + "/#/educational"
            type = "_blank"
            break
        case "Trade Ideas":
            path = "/trade-ideas"
            break
        case "A.I Technical Screener":
            path = "/ai-technical-screener"
            break
        case "Smart Score":
            path = "/smart-score"
            break
        case "Market News":
            path = "/market-news"
            break
        case "Economic Calendar":
            path = "/economic-calendar"
            break
        case "Profile":
            path = "/profile"
            break

        case "Verification":
            path = "/account-verification"
            break

        case "Referral":
            path = "/referral"
            break

        case "Wallet Operations":
            path = "/wallet-operations"
            break

        case "Deposit":
            path = "/wallet-deposit"
            break

        case "Withdrawal":
            path = "/wallet-withdrawal"
            break

        case "Client Deposits/Withdrawals":
            path = "/client-deposits-withdrawals"
            break
        case "Client Trades":
            path = "/client-trades"
            break
        case "Client Trading Accounts":
            path = "/client-trading-accounts"
            break
        case "Client Listing":
            path = "/client-listing"
            break
        case "Volume Commission":
            path = "/volume-commission"
            break
        case "Spread Earnings Commission":
            path = "/spread-earnings-commission"
            break
        case "Profit Sharing Commission":
            path = "/profit-sharing-commission"
            break

        case "Wallet":
            sub =
                brandModuleSetting &&
                brandModuleSetting
                    ?.filter((item) => item?.parent?.name === name && item?.isEnabled === true)
                    ?.map((subItem) => {
                        let subPath
                        let subType
                        switch (subItem.name) {
                            case "Wallet Operations":
                                subPath = "/wallet-operations"
                                break
                            case "Withdrawal":
                                subPath = "/wallet-withdrawal"
                                break
                            case "Deposit":
                                subPath = "/wallet-deposit"
                                break
                        }
                        return {
                            key: subItem.name,
                            label: subItem.name,
                            path: subPath,
                            type: subType,
                            categoryName: subItem.categoryName,
                        }
                    })
            break

        case "Loyalty Program":
            path = "/loyalty-program"
            break

        case "Self-Trade":
            path = "/self-trade"
            break

        case "Analysis":
            sub = brandModuleSetting
                ?.filter((item) => item?.parent?.name === name && item?.isEnabled === true)
                .map((subItem) => {
                    let subPath
                    let subType
                    switch (subItem.name) {
                        case "LIVE Broadcast":
                            subPath = "/live-broadcast"
                            break
                        case "Newsletter":
                            subPath = "https://www." + cmsUrl + "/#/newsletters"
                            subType = "_blank"
                            break
                        case "Education":
                            subPath = "https://www." + cmsUrl + "/#/educational"
                            subType = "_blank"
                            break
                        case "Trade Ideas":
                            subPath = "/trade-ideas"
                            break
                        case "A.I Technical Screener":
                            subPath = "/ai-technical-screener"
                            break
                        case "Smart Score":
                            subPath = "/smart-score"
                            break
                        case "Market News":
                            subPath = "/market-news"
                            break
                        case "Economic Calendar":
                            subPath = "/economic-calendar"
                            break
                        case "Market Buzz":
                            subPath = "/market-buzz"
                            break
                    }
                    return {
                        key: subItem.name,
                        label: subItem.name,
                        path: subPath,
                        type: subType,
                        categoryName: subItem.categoryName,
                    }
                })
            break

        case "Markets":
            sub = brandModuleSetting
                ?.filter((item) => item?.parent?.name === name && item?.isEnabled === true)
                .map((subItem) => {
                    let subPath
                    let subType
                    switch (subItem.name) {
                        case "Quotes":
                            subPath = "/quotes"
                            break
                        case "Charts":
                            subPath = "/charts"
                            break
                        case "Trade":
                            subPath = "/trade"
                            break
                    }
                    return {
                        key: subItem.name,
                        label: subItem.name,
                        path: subPath,
                        type: subType,
                        categoryName: subItem.categoryName,
                    }
                })
            break

        case "Reports":
            sub = brandModuleSetting
                .filter((item) => item?.parent?.name === name && item?.isEnabled === true)
                .map((subItem) => {
                    let subPath
                    let subType
                    switch (subItem.name) {
                        case "Client Deposits/Withdrawals":
                            subPath = "/client-deposits-withdrawals"
                            break
                        case "Client Trades":
                            subPath = "/client-trades"
                            break
                        case "Client Trading Accounts":
                            subPath = "/client-trading-accounts"
                            break
                        case "Client Listing":
                            subPath = "/client-listing"
                            break
                        case "Volume Commission":
                            subPath = "/volume-commission"
                            break
                        case "Spread Earnings Commission":
                            subPath = "/spread-earnings-commission"
                            break
                        case "Profit Sharing Commission":
                            path = "/profit-sharing-commission"
                            break
                    }
                    return {
                        key: subItem.name,
                        label: subItem.name,
                        path: subPath,
                        type: subType,
                        categoryName: subItem.categoryName,
                    }
                })
            break

        case "FAQ":
            path = "/faq"
            break

        case "Trading Account":
            path = "/trading-account"
            break

        case "Community":
            path = "/community"
            break

        case "Promotions":
            path = "https://" + cmsUrl + "/#/promotion"
            type = "_blank"
            break

        case "Support":
            path = "/support"
            break

        case "Activities":
            sub = brandModuleSetting
                ?.filter((item) => item?.parent?.name === name && item?.isEnabled === true)
                .map((subItem) => {
                    let subPath
                    let subType
                    switch (subItem.name) {
                        case "Mission":
                            subPath = "/mission"
                            break

                        case "Promo Materials":
                            subPath = "/promo-materials"
                            break
                    }
                    return {
                        key: subItem.name,
                        label: subItem.name,
                        path: subPath,
                        type: subType,
                        categoryName: subItem.categoryName,
                    }
                })
            break

        case "Mission":
            path = "/mission"
            break

        case "Promo Materials":
            path = "/promo-materials"
            break

        case "Reward":
            sub = brandModuleSetting
                ?.filter((item) => item?.parent?.name === name && item?.isEnabled === true)
                .map((subItem) => {
                    let subPath
                    let subType
                    switch (subItem.name) {
                        case "Voucher":
                            subPath = "/reward"
                            break
                    }
                    return {
                        key: subItem.name,
                        label: subItem.name,
                        path: subPath,
                        type: subType,
                        categoryName: subItem.categoryName,
                    }
                })
            break

        case "Voucher":
            path = "/reward"
            break

        case "Liquidity Pool":
            path = "/liquidity-pool"
            break

        case "MAM/PAMM":
            path = "/mam-pamm"
            break

        case "Copy Trade":
            path = "/copy-trade"
            break
        case "Market Buzz":
            path = "/market-buzz"
            break
    }

    return {
        path: path,
        sub: sub,
        type: type,
        categoryName: categoryName,
    }
}

export default getMobileRoute
