import {Col, Layout, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {globalRoutes} from "../../../_common/global_state";

const BottomNavigationBar = () => {
    const {Footer} = Layout;
    const navigate = useNavigate();
    const [span, setSpan] = useState(0);
    const routes = useRecoilValue(globalRoutes);

    const getSpan = () => {
        let colSpan
        switch (routes?.bottom?.filter(route => route.key !== "more")?.length) {
            case 4:
                colSpan = 6;
                break;
            case 5:
                colSpan = 4;
                break;
            case 3:
                colSpan = 8;
                break;
            case 2:
                colSpan = 12;
                break;
            case 1:
                colSpan = 3;
                break;
            default:
                colSpan = 4
        }

        setSpan(colSpan);
    }

    useEffect(() => {
        getSpan()
    }, [routes?.bottom])

    return (
        <Footer id={"bottom-navigation-bar"}>
            <Row gutter={[16, 16]} justify={"space-around"}>
                {
                    routes?.bottom?.length > 1 ?
                        span && routes?.bottom?.filter(route => route.key !== "more")?.map(route => {

                            return (
                                <Col span={span}>
                                    <div className={"text-center my-2"}
                                         key={route?.label}
                                         onClick={() => {
                                             route?.type === "_blank" ? window.open(route?.path, '_blank') : navigate(route?.path)
                                         }}
                                         style={{cursor: "pointer"}}
                                    >
                                        {route?.icon}
                                    </div>
                                </Col>
                            )
                        }) :
                        span && [...routes?.bottom]?.map(route => {
                            return (
                                route ? (
                                    <Col span={span}>
                                        <div className={"text-center my-2"}
                                             key={route?.label}
                                             onClick={() => {
                                                 route?.type === "_blank" ? window.open(route?.path, '_blank') : navigate(route?.path)
                                             }}
                                             style={{cursor: "pointer"}}
                                        >
                                            {route?.icon}
                                        </div>
                                    </Col>
                                ) : <></>
                            )
                        })
                }
            </Row>
        </Footer>
    )
}

export default BottomNavigationBar