import useShared from "../../../../../shared/_common/_shared";


export default function AntosEmptyData({title}) {
    const {IconCommon} = useShared()
    return (
        <div className={"text-center"}>
            <IconCommon iconName={"empty"} className={"empty-data"} title={title || "noData"} isAlwaysInActive={true}/>
        </div>
    )
}