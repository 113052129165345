import {Button, Card, Col, Collapse, Drawer, QRCode, Row, Typography} from "antd";
import {useRecoilValue} from "recoil";
import {globalReferralSettingState, globalUserDetailState,} from "../../../../_common/global_state";
import {useTranslation} from "react-i18next";
import {CopyOutlined, LeftOutlined} from "@ant-design/icons";
import React from "react";
import useShared from "../../../../_common/_shared";

const QrModal = (props) => {
    const {isModalOpen, handleCancel} = props;

    const userDetails = useRecoilValue(globalUserDetailState);
    const qrReferral = useRecoilValue(globalReferralSettingState);
    const mainDomain = window.location.hostname.split(".").slice(-2).join(".");
    const {t} = useTranslation()
    const {copyToClipboard} = useShared()

    const referralDetail = [
        {
            title: "user.affiliateCode",
            url: userDetails?.affiliateCode,
            enabled: true
        },
        {
            title: "referral.homepage",
            url: "https://" + mainDomain + "/?affiliate=" + userDetails?.affiliateCode,
            enabled: qrReferral?.referralLink
        },
        {
            title: "referral.live",
            url: "https://member." + mainDomain + "/#/register?type=individual&affiliate=" + userDetails?.affiliateCode,
            enabled: qrReferral?.liveReferralLink
        },
        {
            title: "referral.demo",
            url: "https://member." + mainDomain + "/#/register?type=demo&affiliate=" + userDetails?.affiliateCode,
            enabled: qrReferral?.demoReferralLink
        }
    ]

    const items = referralDetail.filter(item => item?.enabled).map((item, i) => ({
        key: item?.title,
        label: (
            <Card size={"small"} className={i !== 0 ? "mt-2" : ""}>
                <Row align={"middle"}>
                    <Col span={10}>
                        <p>{t(item?.title)}</p>
                    </Col>
                    <Col span={13}>
                        <Typography.Text ellipsis={{rows: 1, suffix: item?.url?.slice(-5)?.trim()}}>
                            {item?.url?.slice(0, item?.url?.length - 5)}
                        </Typography.Text>
                    </Col>
                    <Col className={"text-end"} span={1}>
                        <Button onClick={() => copyToClipboard(item?.url)} className={"copy-button"} type={"link"} icon={<CopyOutlined/>}/>
                    </Col>
                </Row>
            </Card>
        ),
        children: (
            <Row align={"middle"}>
                <Col span={14} push={10}>
                    <QRCode value={item?.url}/>
                </Col>
            </Row>
        ),
        showArrow: false,
        collapsible: item?.title === "user.affiliateCode" ? "disabled" : null
    }))

    return (
        <Drawer
            open={isModalOpen}
            footer={null}
            closeIcon={<Button icon={<LeftOutlined/>} shape={"circle"}/>}
            onClose={handleCancel}
            className={"referral-drawer"}
            getContainer={() => document.getElementById('layout-2')}
        >
            <h2 className={"mb-4"}>{t("referral.modal.title")}</h2>
            {/*todo - translation*/}
            <h3 className={"mb-3"}>Invite via</h3>
            <Collapse items={items} ghost accordion/>
        </Drawer>
    );
};

export default QrModal;
