import {Button, Card, Col, Image, Row, Space} from "antd";
import dummy from "../../../../assets/image/dummy_obj.png";
import {useTranslation} from "react-i18next";

const PlatformDownloadCard = (props) => {
    const {data} = props;
    const language = localStorage.getItem("language");

    const platformDisplayName =
        data?.displayNameLanguage?.find((v) => v?.language === language)?.label ??
        data?.displayNameLanguage?.find((v) => v?.language === "en")?.label ??
        data?.displayName;

    return (
        <a href={data?.link} target="_blank">
            <Card size={"small"}>
                <Space size={"large"}>
                    <Image
                        src={data?.icon ? data?.icon : data?.defaultIcon ? data?.defaultIcon : dummy}
                        className={"platform-download-image"}
                        preview={false}
                    />
                    <p>{platformDisplayName}</p>
                </Space>
            </Card>
        </a>
    );
};

export default PlatformDownloadCard;
