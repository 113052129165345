import {Button, Checkbox, Modal} from "antd";
import {useState} from "react";

const AddToHomeScreenModal_Antos = ({open, doNotRemindOnClick}) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        document.querySelector(".ant-layout") &&
        <Modal
            open={isOpen && open}
            closable={false}
            centered
            maskClosable={false}
            getContainer={() => document.querySelector(".ant-layout")}
            footer={
                <Button type={"primary"} block onClick={() => setIsOpen(false)}>I understand</Button>
            }
        >
            <h3 className={"text-center mb-3"}><strong>Add to Home Screen</strong></h3>

            <p>
                To ensure the smooth use of Antos, you need to add this webpage to your home screen.
            </p>
            <p className={"secondary-text"}>
                Tap the "Share" button at the bottom of Safari browser, choose "Add to Home Screen" from the options. Subsequently, you can directly open Antos from your home screen.
            </p>

            <Checkbox onChange={value => doNotRemindOnClick(value)} className={"mt-3"}>Do not remind again within 7 days</Checkbox>
        </Modal>
    )
}

export default AddToHomeScreenModal_Antos